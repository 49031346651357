<!--
 * @Author: huadan
 * @Date: 2021-04-19 10:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-09 09:39:52
 * @Description: 结算
-->

<style lang="less" scoped>
.payment {
  padding-top: 30px;

  .payment-table {
    margin: 0 20px;

    .filter-button {
      width: 88px;
      text-align: center;
    }

    .filter3-button {
      width: 88px;
    }

    .table-operations {
      margin-top: 20px;
      margin-bottom: 10px;

      .form-item {
        display: inline-block;
        margin-bottom: 10px;
      }

      .range-picker {
        width: 100% !important;
      }
    }
  }
  .disflex {
    display: inline-flex !important;
  }

  .custom-page-size-box {
    display: flex;
    height: 45px;
    justify-content: right;
    align-items: center;
  }

  .pagination {
  }

  .custom-page-size {
    margin-left: 9px;
    width: 90px;
    height: 32px;
  }

  .input-unit {
    margin-left: 5px;
  }

  .input-box {
    position: relative;
  }
  .dropup {
    z-index: 99;
    position: absolute;
    font-size: 20px;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }

  .pdf-config {
    width: 60px;
  }

  /deep/.ant-col.ant-col-3.ant-form-item-label {
    width: 100px;
    text-align: right;
  }
  /deep/.ant-form-item-control-wrapper {
    flex: 1;
  }
}
</style>

<template>
  <NeoPageLayout class="payment">
    <!--  结算表格过滤 -->
    <div class="payment-table">
      <a-radio-group v-model="radioGroup">
        <a-radio-button @click="clickTab" class="filter-button" value="">
          {{ $t('payment.all') }}
        </a-radio-button>
        <a-radio-button
          @click="clickTab"
          width="170px"
          value="1"
          v-if="$role('payment_customer') || $role('payment_customer_admin')"
        >
          {{ $t('payment.confirm_by_customer') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ waitOrderConfirmCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" width="170px" value="2">
          {{ $t('payment.confirm_by_provider') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ waitProviderConfirmCount }}</NeoTag>
        </a-radio-button>
        <a-radio-button @click="clickTab" value="3" v-if="$role('payment_customer') || $role('payment_customer_admin')">
          {{ $t('payment.rejected_by_provider') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ rejectedCount }}</NeoTag>
        </a-radio-button>
        <!-- <a-radio-button @click="clickTab" width="170px" value="10" v-if="$role('payment_provider')">
          {{ $t('payment.confirm_by_provider') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ waitProviderConfirmCount }}</NeoTag>
        </a-radio-button> -->
        <a-radio-button @click="clickTab" width="145px" value="4" v-if="$role('payment_provider')">
          {{ $t('payment.confirmed_by_provider') }}
          <NeoTag class="ml-1" type="warning" size="mini">{{ alreadyConfirm }}</NeoTag>
        </a-radio-button>
      </a-radio-group>
      <!--  结算表格查询 -->
      <div class="table-operations">
        <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item :label="$t('payment.status')" :style="{ width: '45%' }" class="form-item disflex">
            <a-select
              v-model="form.status"
              @change="handleStatusChange"
              :placeholder="$t('orderList.select_order_status')"
              mode="multiple"
            >
              <template v-for="item of Object.entries(localeDict.paymentStatusSelect)">
                <a-select-option :key="item[0]" :value="item[0]">
                  {{ item[1] }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            class="form-item disflex"
            :label="$t('payment.order')"
            :style="{ width: 'calc(45% - 10px)' }"
            :wrapperCol="{ span: 21 }"
          >
            <a-input v-model="form.name" :placeholder="$t('payment.input_order_nn')" />
          </a-form-model-item>
          <a-form-model-item class="form-item" :wrapper-col="{ span: 1, offset: 1 }" :style="{ width: '3%' }">
            <a-button @click="resetSearch()" class="filter3-button ml-2">{{ $t('reset') }}</a-button>
          </a-form-model-item>
          <a-form-model-item class="form-item disflex" :label="$t('payment.update_time')" :style="{ width: '45%' }">
            <a-range-picker
              class="range-picker"
              :show-time="{
                format: 'HH:mm',
                defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
              }"
              :value="form.updateTime"
              format="YYYY-MM-DD HH:mm"
              @change="onUpdateTimeChange"
            >
              <a-icon slot="suffixIcon" type="dateString" />
            </a-range-picker>
          </a-form-model-item>
          <a-form-model-item
            class="form-item disflex"
            :label="$t('payment.entity')"
            :style="{ width: 'calc(45% - 10px)' }"
            :wrapperCol="{ span: 21 }"
            v-if="$role('payment_customer') || $role('payment_customer_admin')"
          >
            <a-input v-model="form.cusAgencyName" :placeholder="$t('payment.input_entity')" />
          </a-form-model-item>
          <a-form-model-item
            class="form-item disflex"
            :style="{ width: 'calc(45% - 10px)' }"
            :wrapperCol="{ span: 21 }"
            v-if="$role('payment_provider')"
          >
          </a-form-model-item>
          <a-form-model-item class="form-item" :wrapper-col="{ span: 1, offset: 1 }" :style="{ width: '3%' }">
            <a-button type="primary" @click="searchTable" class="filter3-button ml-2">{{ $t('search') }}</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-button type="primary mb-1 mr-2" @click="confirmPaymentSelected()" v-if="!invoiceVisible">{{
        $t('payment.confirm')
      }}</a-button>
      <a-button type="mb-1 mr-2" :loading="pdfLoading" @click="exportPayment()" v-if="!invoiceVisible">{{
        $t('payment.export')
      }}</a-button>
      <a-select
        v-model="pdf.format"
        :showArrow="false"
        :placeholder="$t('pdf.format')"
        class="pdf-config mb-1 mr-2"
        v-if="!invoiceVisible"
      >
        <template v-for="item of localeDict.pageFormat">
          <a-select-option :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </template>
      </a-select>
      <a-select
        v-model="pdf.landscape"
        :showArrow="false"
        :placeholder="$t('pdf.landscape')"
        class="pdf-config mb-1"
        v-if="!invoiceVisible"
      >
        <template v-for="(item, key) of localeDict.pageLandscape">
          <a-select-option :key="key" :value="key">
            {{ item }}
          </a-select-option>
        </template>
      </a-select>
      <a-button type="primary mb-1 mr-2" @click="invoiceVisibleHandle" v-if="invoiceVisible">{{
        $t('payment.invoice_info')
      }}</a-button>
      <!-- 结算表格 -->
      <a-table
        class="striped no-border"
        rowKey="id"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        size="middle"
        :scroll="{ x: $g.scrollXWidth }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onChange }"
      >
        <a-table-column align="center" :width="60">
          <template slot-scope="text">
            <NeoTag v-if="text.isTerminate" class="ml-1" type="danger" size="mini">{{ $t('payment.stop') }}</NeoTag>
          </template>
        </a-table-column>
        <!--订单号-->
        <a-table-column key="num" :title="$t('payment.order_num')">
          <template slot-scope="text">
            {{ text.code }}
          </template>
        </a-table-column>
        <!--订单名称-->
        <a-table-column key="name" :title="$t('payment.order_name')" :width="260">
          <template slot-scope="text">
            <span class="uline" @click="viewPayment(text)"
              ><a>{{ text.name | defined }}</a></span
            >
          </template>
        </a-table-column>
        <!--结算状态-->
        <a-table-column key="settlementStatus" :title="$t('payment.status')">
          <template slot-scope="text">
            <span>{{ localeDict.paymentStatus[text.settlementStatus] | defined }}</span>
          </template>
        </a-table-column>
        <!--供应商实体-->
        <a-table-column key="entity" :title="$t('payment.entity')" v-if="$role('payment_customer')">
          <template slot-scope="text">
            {{ text.supplierName }}
          </template>
        </a-table-column>
        <!--下单经理-->
        <a-table-column key="orderManager" :title="$t('payment.order_manager')" v-if="$role('payment_customer_admin')">
          <template slot-scope="text">
            {{ text.presentManagerName | defined }}
          </template>
        </a-table-column>
        <!--订单报价 -->
        <a-table-column key="offer" :title="$t('payment.order_offer')">
          <template slot-scope="text">
            {{ text.quotePrice ? `${currencyUnit(text.currency)}${numeral(text.quotePrice).format('0.[00]')}` : '---' }}
          </template>
        </a-table-column>
        <!-- 结算金额 -->
        <a-table-column key="amount" :title="$t('payment.amount')">
          <template slot-scope="text">
            <span :style="text.status === 11 && text.settlementPrice ? { color: 'red' } : ''">
              {{
                text.settlementPrice !== null
                  ? `${currencyUnit(text.currency)}${numeral(text.settlementPrice).format('0.[00]')}`
                  : '---'
              }}
            </span>
          </template>
        </a-table-column>
        <!-- 结算更新时间 -->
        <a-table-column key="updateTime" :title="$t('payment.update_time')" :width="200">
          <template slot-scope="text">
            {{ moment(text.updateDate).format($t('dateFormat.f')) | defined }}
          </template>
        </a-table-column>
        <!-- 结算表格操作列 -->
        <a-table-column key="action" :title="$t('orderList.order_action')" :width="100">
          <template slot-scope="text">
            <!-- 下单方端 -->
            <div v-if="$role('payment_customer') || $role('payment_customer_admin')">
              <span v-if="[8, 9, 11].some((val) => val == text.status)" @click="confirmPayment(text)"
                ><a>{{ $t('payment.confirm') }}</a></span
              >
              <!-- <span v-else-if="[10, 12, 13].some((val) => val == text.status)" @click="viewPayment(text)"
                ><a>{{ $t('payment.view') }}</a></span
              > -->
              <span v-else></span>
            </div>
            <!-- 供应商端 -->
            <div v-else>
              <span v-if="[10].some((val) => val == text.status)" @click="confirmPayment(text)"
                ><a>{{ $t('payment.confirm') }}</a></span
              >
              <!-- <span v-else-if="[11, 12, 13].some((val) => val == text.status)" @click="viewPayment(text)"
                ><a>{{ $t('payment.view') }}</a></span
              > -->
              <span v-else></span>
            </div>
          </template>
        </a-table-column>
      </a-table>
      <!-- <a-pagination
        class="mt-3 fr"
        show-size-changer
        :default-current="current"
        :current="current"
        @change="onChangePage"
        :total="totalNum"
        :page-size="pageSize"
        @showSizeChange="onShowSizeChange"
      /> -->
      <!--  分页 -->
      <div class="custom-page-size-box mt-3 fr">
        <a-pagination
          class="pagination"
          v-model="current"
          :total="totalNum"
          :page-size="pageSize"
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        />

        <div class="input-box">
          <a-input type="text" class="custom-page-size" v-model="customPageSize" @keyup.enter="submit" />
          <a-dropdown placement="topCenter" :trigger="['click']">
            <span class="dropup" @click="(e) => e.preventDefault()">^</span>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="selectdrop(10)">10</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="selectdrop(20)">20</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="selectdrop(30)">30</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>

        <span class="input-unit">条/页</span>
      </div>
    </div>
    <a-drawer width="864px" :visible="visible" @close="handleClose">
      <template v-slot:title>
        <a-space :size="10">
          <span>{{ $t('payment.drawer_payment') }}</span>
          <NeoTag v-if="ids.length < 2" shape :type="drawerTitleTagType || 'warning'">{{ drawerTitleTag }}</NeoTag>
        </a-space>
      </template>
      <!-- 结算单 -->
      <PaymentInfo
        v-if="visible"
        :ids="ids"
        :isEdit="isEdit"
        @close="handlePaymentClose"
        @resetFetch="resetFetch"
        @resetSelected="resetSelected"
      />
    </a-drawer>
    <!-- 开票信息 -->
    <a-modal
      :title="$t('payment.invoice_info')"
      :cancelText="$t('cancel')"
      :okText="$t('submit')"
      :visible="modalVisible"
      @cancel="handleCancel"
      @ok="handleOk"
      width="684px"
    >
      <a-form-model
        ref="form"
        :rules="rules"
        :model="invoice_form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item :label="$t('payment.drawer_invoice_code')" prop="invoiceCode">
          <a-input
            :value="invoice_form.invoiceCode"
            @change="invoiceCodeChange"
            :maxLength="20"
            :placeholder="$t('payment.input_invoice_code')"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </NeoPageLayout>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'
import NeoTag from '@/components/common/NeoTag'
import PaymentInfo from './component/PaymentInfo'
import debounce from 'lodash/debounce'
import Cookies from 'js-cookie'
import { downloadBlob } from '@/utils/utils'
const { storageKey, validate } = window.$g

export default {
  name: 'payment',
  components: { NeoTag, PaymentInfo },
  data() {
    return {
      pagination: {},
      current: 1,
      totalNum: 0,
      pageSize: 10,
      loading: false,
      //行选择器
      radioGroup: '',
      dataSource: [],
      labelCol: { span: 3 },
      wrapperCol: { span: 19 },
      //查询参数
      form: {
        status: [],
        cusAgencyName: '',
        name: '',
        updateTime: [],
      },
      searchForm: {},
      //当前status
      status: '',
      visible: false,
      modalVisible: false,
      invoiceVisible: false,
      invoice_form: {
        invoiceCode: '',
      },
      validate,
      rules: {
        invoiceCode: [validate.int, validate.required],
      },
      idArr: [],
      // statusList: [
      //   {
      //     value: '8,9',
      //     name: this.$t('dict.payment_status9'),
      //   },
      //   {
      //     value: '10',
      //     name: this.$t('dict.payment_status10'),
      //   },
      //   {
      //     value: '11',
      //     name: this.$t('dict.payment_status11'),
      //   },
      //   {
      //     value: '12',
      //     name: this.$t('dict.payment_status12'),
      //   },
      //   {
      //     value: '13',
      //     name: this.$t('dict.payment_status13'),
      //   },
      // ],
      localeDict: window.$g.localeDict,
      rowSelections: {},
      selectedRowKeys: [],
      selectedRows: [],
      isEdit: false,
      waitConfirmCount1: 0,
      waitConfirmCount2: 0,
      waitProviderConfirmCount: 0,
      rejectedCount: 0,
      alreadyConfirm: 0,
      tabCount: [
        {
          name: 'waitConfirmCount1',
          status: '8',
        },
        {
          name: 'waitConfirmCount2',
          status: '9',
        },
        {
          name: 'waitProviderConfirmCount',
          status: '10',
        },
        {
          name: 'rejectedCount',
          status: '11',
        },
        {
          name: 'alreadyConfirm',
          status: '12',
        },
      ],
      pdfLoading: false,
      drawerTitleTag: null,
      drawerTitleTagType: null,
      numeral,
      ids: [],
      // 自定义分页的条数
      customPageSize: 10,
      // pdf导出参数
      pdf: {
        format: 'A4',
        landscape: 'false',
      },
    }
  },

  watch: {
    radioGroup(val) {
      //根据所选状态筛选表格数据
      this.invoiceVisible = val == '4'
    },
  },
  computed: {
    waitOrderConfirmCount() {
      return ~~this.waitConfirmCount1 + ~~this.waitConfirmCount2
    },
    // 结算单是否可以导出
    isExportOrder() {
      let result = { value: false, popInfo: '', props: [] }
      // 全部是待下单方确认结算 => 不能导出
      if (this.selectedRows.every((item) => [8, 9].some((val) => val == item.status))) {
        result.value = false
        result.popInfo = this.$t('payment.had_confirm_status')
        return result
      }
      // 待供应商确认结算/供应商已确认/供应商已驳回/已开票 => 能导出
      if (this.selectedRows.every((item) => [10, 12, 11, 13].some((val) => val == item.status))) {
        result.value = true
        result.rows = this.selectedRows
        result.props = this.selectedRowKeys
        return result
      }
      // 待供应商确认&&其他状态 => 忽略待供应商确认状态并且导出其他状态
      if (
        this.selectedRows.some((item) => item.status == 8 || item.status == 9) &&
        !this.selectedRows.every((item) => item.status == 8 || item.status == 9)
      ) {
        result.value = true
        result.rows = this.selectedRows.filter((item) => item.status != 8 && item.status != 9)
        result.props = this.selectedRows.filter((item) => item.status != 8 && item.status != 9).map((item) => item.id)
        return result
      }
      return result
    },
    // 结算单是否可以确认
    isConfirmOrder() {
      let result = { value: false, popInfo: '', props: [] }
      // 下单方：
      if (this.$role('order_orderList_customer')) {
        // 全部是待下单方确认结算/供应商驳回
        if (this.selectedRows.every((item) => [8, 9, 11].some((val) => val == item.status))) {
          result.value = true
          result.props = this.selectedRowKeys
          return result
        }
        // 部分是待下单方确认结算/供应商驳回
        if (this.selectedRows.some((item) => [8, 9, 11].some((val) => val == item.status))) {
          result.value = true
          result.props = this.selectedRows
            .filter((item) => [8, 9, 11].some((val) => val == item.status))
            .map((item) => item.id)
          return result
        }
        // 全部是待供应商确认/全部是供应商已确认/已开票
        if (this.selectedRows.every((item) => [10, 12, 13].some((val) => val == item.status))) {
          result.value = false
          result.popInfo = this.$t('payment.no_confirm_again')
          return result
        }
      }

      // 供应商：
      if (this.$role('order_orderList_provider')) {
        // 全部是待供应商确认结算
        if (this.selectedRows.every((item) => item.status == 10)) {
          result.value = true
          result.props = this.selectedRowKeys
          return result
        }
        // 部分是 待供应商确认结算
        if (this.selectedRows.some((item) => item.status == 10)) {
          result.value = true
          result.props = this.selectedRows.filter((item) => item.status == 10).map((item) => item.id)
          return result
        }
        // 待下单方确认结算/供应商已确认/供应商已驳回/已开票
        if (this.selectedRows.every((item) => [8, 9, 12, 11, 13].some((val) => val == item.status))) {
          result.value = false
          result.popInfo = this.$t('payment.no_confirm_again')
          return result
        }
      }
      return result
    },
  },

  created() {
    this.handleUrlParams()
  },

  methods: {
    handleUrlParams() {
      const { tabVal: tab, cusAgencyName } = this.$route.query
      if (tab) {
        // if (tab === '8,9;11') {
        //   this.radioGroup = ''
        //   this.status = tab.split(';').join(',')
        //   this.form.status = tab.split(';')
        // } else {
        //   if (['8', '9', '8,9'].some((item) => item === tab)) {
        //     this.radioGroup = '8,9'
        //   } else {
        //     if (tab == '10' && !this.$g.isEmpty(cusAgencyName)) {
        //       this.form.status.push('10')
        //       this.form.cusAgencyName = cusAgencyName
        //       this.searchTable()
        //     } else {
        //       this.radioGroup = tab
        //     }
        //   }
        //   this.status = tab
        // }
        if (tab === '1,3') {
          this.radioGroup = ''
          this.form.status = tab.split(',')
        } else if (tab === '2' && !this.$g.isEmpty(cusAgencyName)) {
          this.form.status.push('2')
          this.form.cusAgencyName = cusAgencyName
          this.searchTable()
        } else {
          this.radioGroup = tab
        }
        this.status = tab
      }

      this.fetch()
      this.getTabCount()
    },
    getSearchForm() {
      const orderCodeOrName = this.form.name ?? ''
      const startUpdateDate = this.form.updateTime?.length > 1 ? this.form.updateTime[0] : ''
      const endUpdateDate = this.form.updateTime?.length > 1 ? this.form.updateTime[1] : ''
      const cusAgencyName = this.form.cusAgencyName ?? ''
      const params = {
        orderCodeOrName,
        startUpdateDate,
        endUpdateDate,
        cusAgencyName,
      }

      return params
    },
    clickTab({ target }) {
      const val = target.value
      this.current = 1
      this.status = val
      this.emptySearch()
      this.emptySelected()
      this.fetch()
    },
    // 币种单位
    currencyUnit(currency) {
      const res = this.$store.getters['app/getDictLabel']('CURRENCY', currency) || ''
      return res.split(';')[1]
    },
    getTabCount() {
      this.tabCount.forEach((item) => {
        this.fetchStatusCount(item)
      })
    },
    // 获取状态的数量
    async fetchStatusCount({ name, status }) {
      try {
        const data = await this.$http('orderStatusCount', { status })
        const target = data.find((item) => item.status == status)
        const count = target ? target.count : 0
        this[name] = count
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    // 清空多选
    emptySelected() {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    onChange(selectedRowKeys, selectedRows) {
      //选中的数据的key
      console.log({ selectedRowKeys, selectedRows })
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    isNoSearchOption() {
      return Object.values(this.form).every((val) => !val)
    },
    searchTable() {
      this.current = 1
      // 如果没有筛选条件，不跳转tab
      this.searchForm = this.getSearchForm()
      if (this.isNoSearchOption()) {
        this.fetch(this.searchForm)
      } else {
        this.status = this.form.status?.join(',')
        this.radioGroup = ''
        this.fetch(this.searchForm)
      }
    },
    handleStatusChange(status) {
      console.log(`status`, status)
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      })
    },

    onChangePage(page, pageSize) {
      this.current = page
      this.pageSize = pageSize
      this.fetch({ limit: this['pageSize'], page, ...this.searchForm })
      this.emptySelected()
    },
    onShowSizeChange(pageSize, current) {
      this.pageSize = Number(pageSize)
      this.current = current
      this.fetch({ limit: pageSize, ...this.searchForm })
    },

    fetch: debounce(
      async function (params = {}) {
        try {
          this.loading = true
          const data = await this.$http('orderSettlement', {
            ...params,
            status: this.status,
            page: this.current,
            order: 'desc',
            orderField: 'update_date',
          })
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.dataSource = data.list
          this.pagination = pagination
          this.totalNum = pagination.total
          this.getTabCount()
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$httpNotify(err)
        }
      },
      200,
      { leading: true, trailing: false }
    ),

    // 重置表单
    resetSearch() {
      this.current = 1
      this.emptySearch()
      this.status = this.radioGroup
      this.searchForm = this.getSearchForm()
      this.fetch()
    },
    // 清空搜索条件
    emptySearch() {
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          this.form[key] = undefined
        }
      }
      this.searchForm = this.getSearchForm()
    },

    onUpdateTimeChange(date, dateString) {
      this.form.updateTime = dateString
    },
    sortClass(sortData, key) {
      const groupBy = (array, f) => {
        let groups = {}
        array.forEach((o) => {
          let group = JSON.stringify(f(o))
          groups[group] = groups[group] || []
          groups[group].push(o)
        })
        return Object.keys(groups).map((group) => {
          return groups[group]
        })
      }
      const sorted = groupBy(sortData, (item) => {
        return item[key] // 返回需要分组的对象
      })
      return sorted
    },
    async exportPayment() {
      const rows = this.selectedRows
      if (!rows.length) {
        return this.$message.warning(this.$t('payment.selected_statement'))
      }
      const isExportOrder = this.isExportOrder
      if (!isExportOrder.value) {
        return this.$message.warning(isExportOrder.popInfo)
      }
      const { supplierId, supplierName } = isExportOrder.rows[0] ?? {}
      let isSupplierAllSame = true //下单方实体是否都一样
      let exportType = null
      console.log(`isExportOrder.rows`, isExportOrder.rows)
      console.log(`supplierId`, supplierId)
      isExportOrder.rows.forEach((item) => {
        console.log(`item.supplierId`, item.supplierId)
        if (item.supplierId !== supplierId) {
          isSupplierAllSame = false
        }
      })
      console.log(`isSupplierAllSame`, isSupplierAllSame)
      // 下单方实体都一样 => pdf
      // 下单方实体不一样 => zip
      if (!isSupplierAllSame) {
        exportType = 'zip'
      } else {
        exportType = 'pdf'
      }

      const ids = isExportOrder.props
      let name = ''
      let type = ''
      const pdfInfoList = []
      const _ids = ids.length ? ids.join(',') : ''
      console.log(`ids`, ids)
      console.log(`exportType`, exportType)
      if (ids.length <= 1) {
        name = `${isExportOrder?.rows[0].name}_${this.$t('payment.drawer_payment')}`
        type = 'application/pdf;charset-UTF-8'
        pdfInfoList.push({
          ids: _ids,
          pdfName: '',
        })
      } else {
        if (exportType === 'pdf') {
          name = `${supplierName}_${this.$t('payment.drawer_payment')}_${moment().format('YYYYMMDDHHmm')}`
          type = 'application/pdf;charset-UTF-8'
          pdfInfoList.push({
            ids: _ids,
            pdfName: '',
          })
        } else {
          name = `${this.$t('payment.drawer_payment')}_${moment().format('YYYYMMDDHHmm')}`
          type = 'application/zip'
          const groups = this.sortClass([...isExportOrder.rows], 'supplierId')
          groups.forEach((arr) => {
            let __ids = arr.map((_item) => _item.id)?.join(',')
            const name = arr[0].supplierName
            pdfInfoList.push({
              ids: __ids,
              pdfName: `${name}_${this.$t('payment.drawer_payment')}_${moment().format('YYYYMMDDHHmm')}`,
            })
          })
        }
      }

      try {
        this.pdfLoading = true
        const pdf = await this.$http(
          'ossCmdHtmlToPdf',
          {
            oriUrl: 'pdf-payment',
            token: Cookies.get(storageKey.TOKEN),
            format: this.pdf.format,
            landscape: this.pdf.landscape,
            pdfInfoList,
          },
          {
            responseType: 'blob',
          }
        )
        this.pdfLoading = false
        name = name + `.${exportType}`
        downloadBlob(name, type, pdf)
      } catch (err) {
        this.pdfLoading = false
        this.$httpNotify(err)
      }
    },

    confirmPayment(text) {
      text.status === 12 ? (this.drawerTitleTagType = 'success') : (this.drawerTitleTagType = 'warning')
      this.isEdit = true
      this.ids = [text.id]
      this.drawerTitleTag = this.localeDict.paymentStatus[text.settlementStatus]
      this.visible = true
      this.emptySelected()
    },
    confirmPaymentSelected() {
      const selectedData = this.selectedRows
      if (!selectedData[0]) {
        return this.$message.warning(this.$t('payment.selected_statement'))
      }
      const { supplierId } = selectedData[0] ?? {}
      let isPass = true
      this.selectedRows.forEach((item) => {
        if (item.supplierId !== supplierId) {
          isPass = false
        }
      })
      if (!isPass) {
        return this.$message.warning(this.$t('payment.different_cusAgencyId'))
      }
      const isConfirmOrder = this.isConfirmOrder
      if (!isConfirmOrder.value) {
        return this.$message.warning(isConfirmOrder.popInfo)
      }
      this.ids = isConfirmOrder.props
      this.drawerTitleTag = this.localeDict.paymentStatus[selectedData[0].settlementStatus]
      this.isEdit = true
      this.visible = true
    },

    viewPayment(text) {
      this.drawerTitleTag = this.localeDict.paymentStatus[text.settlementStatus] ?? ''
      text.status === 12 ? (this.drawerTitleTagType = 'success') : (this.drawerTitleTagType = 'warning')
      this.isEdit = false
      this.ids = [text.id]
      this.visible = true
      this.emptySelected()
    },

    handleClose() {
      this.visible = false
    },
    handlePaymentClose() {
      this.visible = false
      this.emptySearch()
      this.fetch()
    },
    // 重新请求列表数据并取消多选按钮
    resetFetch() {
      this.fetch()
    },
    resetSelected() {
      this.emptySelected()
    },
    // 显示开票信息弹窗
    invoiceVisibleHandle() {
      const rows = this.selectedRows
      if (!rows[0]) {
        return this.$message.warning(this.$t('payment.selected_statement'))
      }
      // if (rows.every((item) => ~~item.isOrderSpm === 1)) {
      //   this.modalVisible = true
      // } else {
      //   if (rows.some((item) => ~~item.isOrderSpm === 1)) {
      //     return this.$message.warning(this.$t('payment.part_no_invoice_power'))
      //   } else {
      //     return this.$message.warning(this.$t('payment.no_invoice_power'))
      //   }
      // }
      if (this.$g.isSupSpm) {
        this.modalVisible = true
      } else {
        return this.$message.warning(this.$t('payment.no_invoice_power'))
      }
    },
    invoiceCodeChange(obj) {
      obj.target.value = obj.target.value.replace(/[^\d]/g, '')
      this.invoice_form.invoiceCode = obj.target.value
    },
    async handleOk() {
      if (!this.invoice_form.invoiceCode) {
        return this.$message.warning(this.$t('payment.no_invoice_code'))
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            await this.$http('invoice', {
              ids: this.selectedRowKeys,
              invoiceCode: this.invoice_form.invoiceCode,
            })
            this.closeInvoicePop()
            this.loading = false
            this.selectedRowKeys = []
            this.radioGroup = ''
            this.status = ''
            this.fetch()
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    handleCancel() {
      this.closeInvoicePop()
    },
    closeInvoicePop() {
      this.modalVisible = false
      this.$refs.form.clearValidate()
      setTimeout(() => {
        this.invoice_form.invoiceCode = ''
      }, 300)
    },
    // 日期时间选择框的选取控制
    moment,
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day')
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      }
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        }
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      }
    },
    // 输入条数的回车事件
    submit(e) {
      // console.log(e.target.value)
      if (!/(^[1-9]\d*$)/.test(e.target.value)) {
        this.$message.error('请输入正整数')
      } else {
        console.log('wwww')
        this.pageSize = Number(e.target.value)
        this.onShowSizeChange(e.target.value, 1)
      }
    },
    selectdrop(num) {
      console.log('selectdrop', num)
      this.customPageSize = num
      this.pageSize = Number(num)
      this.onShowSizeChange(num, 1)
    },
  },
}
</script>
